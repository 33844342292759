import { onAuthStateChanged } from 'firebase/auth'
import { FC, useEffect } from 'react'
import { auth } from '@netpurpose/api'
import { useRedirectWithOriginalUrl } from '@netpurpose/core'
import { unauthorisedRoutes } from '#config'

export const CurrentUserProvider: FC<{ children: React.ReactNode }> = ({ children }) => {
  const { handleRedirect } = useRedirectWithOriginalUrl({
    // NOTE: index.ts has the logic of where to land the user based on their permissions
    landingPageUrl: '/',
  })

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (authUser) => {
      const { location } = window
      const isOnAuthorisedRoute = Object.values(unauthorisedRoutes).includes(location.pathname)
      if (authUser && isOnAuthorisedRoute) {
        handleRedirect()
      }
      if (!authUser && !isOnAuthorisedRoute) {
        // Need to use location.assign rather than router.push, as the latter
        // causes document.referrer to be lost.
        location.assign(`${location.origin}/login`)
      }
    })
    return unsubscribe
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return children
}
