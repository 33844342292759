import axios, { AxiosHeaders } from 'axios'
import { getToken, log4XXApiErrors, logout } from '@netpurpose/api'
import { httpStatusCode } from '@netpurpose/utils'

// TODO: this createApi function should and will be centralised in the api package
export const createApi = (baseUrl: string) => {
  const api = axios.create({
    baseURL: baseUrl,
  })

  api.interceptors.request.use(async (config) => {
    const token = await getToken()
    if (!token) {
      return config
    }
    return {
      ...config,
      headers: new AxiosHeaders({
        ...config.headers,
        Authorization: `Bearer ${token}`,
      }),
    }
  })

  api.interceptors.response.use(
    (response) => response,
    async (error) => {
      log4XXApiErrors(error)
      if (error.response?.status === httpStatusCode.unauthorized) {
        const token = await getToken(true)
        if (!token) {
          logout()
        }
      }
      return Promise.reject(error)
    },
  )

  return api
}
